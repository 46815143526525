import React, { useEffect, useState } from "react";
// import "./FileManagement.css";
import { DataGrid } from "@mui/x-data-grid";
import { AiFillEye } from "react-icons/ai";
import { FaLink, FaPencilAlt, FaSearch, FaUniversity, FaUser, FaUserAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { clearError, getAllResourceAction } from "../actions/resourceAction";
import Loading from "../components/Loading/loading";
import MetaData from "../components/MetaData";
import NoDataFound from "../components/NoDataFound";
import Sidebar from "../components/Sidebar";
import AsignedHeader from "../Pages/FileMangement/AsignedHeader";
import { getCommentText, getDateTime, getDateTimeIST, getDateTimeNotification } from "../utils/getDates";
import { getUser } from "../utils/getUser";
import { clearError as userClearErr, getMonitorUsersAction, getMonitorUser } from "../actions/monitoringAction";
import { getNotificationsAction, updateNotificationAction, clearError as nClearError, getAllNotificationsAction } from "../actions/notificationAction";
import { UPDATE_NOTIFICATION_RESET } from "../constants/notification";




const AllResource = ({ underLine, setUnderLine }) => {
    setUnderLine('notification');

    const dispatch = useDispatch();
    const { error, notifications, resultPerPage, totalNotifications, loading } = useSelector((state) => state.getAllNotifications);
    const { user, isAuthenticated } = useSelector((state) => state.users);
    const { error: getUserErr, users } = useSelector((state) => state.getMonitorUsers);

    useEffect(() => {
        // if (users?.length <= 0)
            dispatch(getMonitorUsersAction())
    }, []);

    useEffect(() => {
        searchHandler()
    }, [user]);

    useEffect(() => {
        if (error) {
            console.log(error)
            toast.error(error)
            dispatch(clearError())
        }
        if (getUserErr) {
            // console.log(error)
            toast.error(getUserErr)
            dispatch(userClearErr())
        }
    }, [error, getUserErr]);


   

    console.log(users)

    const [CurrentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        searchHandler()
    }, [CurrentPage]);

    const Navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated)
            if (user?.role !== 'admin' && user?.role !== 'agent' && user?.role !== 'counsellor' && user?.role !== 'processor' && user?.role !== 'supervisor') {
                Navigate('/dashboard');
            }
    }, [user])

    const searchHandler = () => {
        dispatch(getAllNotificationsAction(user?._id, CurrentPage))
    }


    // --------------------------------------- update ---------------------------------------
    const { isUpdated, error: nErr } = useSelector(state => state.updateNotification)

    useEffect(() => {
        if (nErr) {
            // toast.error(nErr)
            nClearError()
        }
        if (isUpdated) {
            // toast.success('Clean n')
            dispatch({ type: UPDATE_NOTIFICATION_RESET })
        }
    }, [nErr, isUpdated])

    const updateNotificationHandle = (n_id) => {

        dispatch(updateNotificationAction(n_id, true))
    }



    return (
        <div className="dashboardMain">
            <MetaData title={"Notification"} />

            <div className={window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"}>
                <Sidebar underLine={underLine} />

            </div>
            {
                loading ? <Loading /> : (

                    <div className="dashboardContainer">
                        <AsignedHeader heading={"Notification "} />


                        <div className="D-ButtonLayer">

                            <div className="BlRow1">
                                <div className="BlItem BlItem4">
                                    <div>
                                        <p>Total Notification</p>
                                        <p>{totalNotifications && totalNotifications}</p>
                                    </div>
                                    <div className="">
                                        <FaUniversity />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="notificationMainBox">
                            <div className="notificationContainer">
                                <div>
                                    {
                                        notifications?.map(item => {
                                            const createdUser = getUser(item.createdUserId, users);
                                            const date = getDateTimeNotification(item.createAt);
                                            const comment = getCommentText(item.type);

                                            return (
                                                <Link to={item?.redirect_id === 'null' ? comment?.url : `${comment?.url}/${item.redirect_id}`} className="notificationItem" onClick={e => updateNotificationHandle(item?._id)}>

                                                    {
                                                        !item?.isSeen && 
                                                        <span className="newTag">New</span>
                                                    }
                                                    <p><FaUser />  {createdUser?.name} - {createdUser?.email}</p>
                                                    <h4>{comment?.text}</h4>
                                                    <p className="date">{date}</p>

                                                </Link>
                                            )
                                        }
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        {
                            totalNotifications > resultPerPage &&

                            <div className="paginationContainer">
                                <Pagination
                                    activePage={CurrentPage}
                                    itemsCountPerPage={resultPerPage}
                                    totalItemsCount={totalNotifications}
                                    onChange={e => setCurrentPage(e)}

                                    className="paginationBox"

                                    // prevPageText="<"
                                    firstPageText="1st"
                                    lastPageText="Last"
                                    // nextPageText=">"
                                    itemClass='page-item'
                                    linkClass='page-link'
                                    activeClass='pageItemActive'
                                    activeLinkClass='pageLinkActive'
                                />
                            </div>
                        }

                    </div>
                )
            }


        </div>
    );
};

export default AllResource;
