import axios from "axios";
import {
  GET_STUDENT_REQUEST,
  GET_STUDENT_SUCCESS,
  GET_STUDENT_FAIL,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAIL,
  CLEAR_ERROR,
  GET_FILE_REQUEST,
  GET_FILE_SUCCESS,
  GET_FILE_FAIL,
  STUDENT_UPDATE_REQUEST,
  STUDENT_UPDATE_SUCCESS,
  STUDENT_UPDATE_FAIL,
  GET_STUDENT_DETAIL_REQUEST,
  GET_STUDENT_DETAIL_SUCCESS,
  GET_STUDENT_DETAIL_FAIL,
  STUDENT_ASIGN_REQUEST,
  STUDENT_ASIGN_SUCCESS,
  STUDENT_ASIGN_FAIL,
  GET_ASIGN_APPLICATION_REQUEST,
  GET_ASIGN_APPLICATION_SUCCESS,
  GET_ASIGN_APPLICATION_FAIL,
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAIL,
  CREATE_STUDENT_REQUEST,
  CREATE_STUDENT_SUCCESS,
  CREATE_STUDENT_FAIL,
  GET_APPLICATIONS_REQUEST,
  GET_APPLICATIONS_SUCCESS,
  GET_APPLICATIONS_FAIL,
  GET_PROCESSED_APPLICATION_REQUEST,
  GET_PROCESSED_APPLICATION_SUCCESS,
  GET_PROCESSED_APPLICATION_FAIL,
  GET_ASIGN_APPLICATION_HISTORY_REQUEST,
  GET_ASIGN_APPLICATION_HISTORY_SUCCESS,
  GET_ASIGN_APPLICATION_HISTORY_FAIL,
  GET_LEADS_REQUEST,
  GET_LEADS_SUCCESS,
  GET_LEADS_FAIL,
  GET_REPORT_REQUEST,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAIL,
  DELETE_DOCUMENTS_REQUEST,
  DELETE_DOCUMENTS_SUCCESS,
  DELETE_DOCUMENTS_FAIL
} from '../constants/fileConstant'
import host from "../utils/var";
import { SEND_OTP_FAIL, SEND_OTP_REQUEST, SEND_OTP_SUCCESS } from "../constants/userConstant";



export const fileUpload = (fileData) => async (dispatch) => {
  try {

    dispatch({ type: UPLOAD_FILE_REQUEST });
    // console.log('ok file 1')
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

    const data = await axios.post(`${host}/api/v1/file/upload`, fileData, config);
    // console.log('ok file 2')

    dispatch({ type: UPLOAD_FILE_SUCCESS, payload: data.data.user });
    // console.log(data.user)
  } catch (error) {
    // console.log('ok file 3')

    dispatch({
      type: UPLOAD_FILE_FAIL,
      payload: error?.response?.data.message,
    });
  }
};


export const deleteDocumentAction = (id, fileId) => async (dispatch) => {
  try {

    dispatch({ type: DELETE_DOCUMENTS_REQUEST });
    // console.log('ok file 1')
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

    const data = await axios.put(`${host}/api/v1/student/delete-document/${id}`, { fileId }, config);
    // console.log('ok file 2')

    dispatch({ type: DELETE_DOCUMENTS_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    // console.log('ok file 3')

    dispatch({
      type: DELETE_DOCUMENTS_FAIL,
      payload: error?.response?.data.message,
    });
  }
};


export const sendOtpAction = (details) => async (dispatch) => {
  try {
    dispatch({ type: SEND_OTP_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };
    // console.log("ok student")

    const data = await axios.post(`${host}/api/v1/send-otp`, details, config);

    dispatch({ type: SEND_OTP_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: SEND_OTP_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

export const getStudentsData = (keyword, userId, page) => async (dispatch) => {
  try {
    dispatch({ type: GET_STUDENT_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };
    // console.log("ok student")

    const data = await axios.get(`${host}/api/v1/students/${userId}?keyword=${keyword}&page=${page}`, config);

    dispatch({ type: GET_STUDENT_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: GET_STUDENT_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

// get Student details
export const getStudent = (id) => async (dispatch) => {
  // console.log("ppp")
  try {
    dispatch({ type: GET_STUDENT_DETAIL_REQUEST });
    const config = { withCredentials: true };
    // console.log("ok student")

    const data = await axios.get(`${host}/api/v1/get/student/${id}`, config);

    dispatch({ type: GET_STUDENT_DETAIL_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: GET_STUDENT_DETAIL_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

export const getUserReport = ({ id, date, date2 }) => async (dispatch) => {
  // console.log("ppp")
  try {
    dispatch({ type: GET_REPORT_REQUEST });
    const config = { withCredentials: true };
    // console.log("ok student")

    const data = await axios.get(`${host}/api/v1/user-report/${id}?date=${date}&date2=${date2}`, config);

    dispatch({ type: GET_REPORT_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: GET_REPORT_FAIL,
      payload: error?.response?.data.message,
    });
  }
};


export const getFlesData = () => async (dispatch) => {
  try {
    dispatch({ type: GET_FILE_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };
    // console.log("ok student")

    const data = await axios.get(`${host}/api/v1/files`, config);

    dispatch({ type: GET_FILE_SUCCESS, payload: data.data.files });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: GET_FILE_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

export const getDashboard = () => async (dispatch) => {
  try {
    dispatch({ type: GET_DASHBOARD_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };
    // console.log("ok student")

    const data = await axios.get(`${host}/api/v1/dashboard`, config);

    dispatch({ type: GET_DASHBOARD_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: GET_DASHBOARD_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

export const studentdUpdate = (id, fileData, documentsFiles) => async (dispatch) => {
  const bodyData = { fileData, documentsFiles }
  try {

    dispatch({ type: STUDENT_UPDATE_REQUEST });
    // console.log('ok file 1')
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

    const data = await axios.put(`${host}/api/v1/student/update/${id}`, bodyData, config);
    // console.log('ok file 2')

    dispatch({ type: STUDENT_UPDATE_SUCCESS, payload: data.data.student });
    // console.log(data.user)
  } catch (error) {
    // console.log('ok file 3')

    dispatch({
      type: STUDENT_UPDATE_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

export const createStudent = (bodyData, fields) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_STUDENT_REQUEST });

    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

    const data = await axios.post(`${host}/api/v1/create-student`, { bodyData, fields }, config);

    dispatch({ type: CREATE_STUDENT_SUCCESS, payload: data.data });

  } catch (error) {
    dispatch({
      type: CREATE_STUDENT_FAIL,
      payload: error?.response?.data.message,
    });
  }
};
export const studentdAsign = (userId, studentId) => async (dispatch) => {
  try {

    dispatch({ type: STUDENT_ASIGN_REQUEST });
    // console.log('ok file 55')
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

    const data = await axios.put(`${host}/api/v1/student/asign/${studentId}`, { userId }, config);
    // console.log('ok file 2')

    dispatch({ type: STUDENT_ASIGN_SUCCESS, payload: data?.data.message });
    // console.log(data.user)
  } catch (error) {
    console.log('ok file 3')

    dispatch({
      type: STUDENT_ASIGN_FAIL,
      payload: "server error",
    });
  }
};
export const getAsignedApplications = (userId, searchWord, page) => async (dispatch) => {
  try {
    dispatch({ type: GET_ASIGN_APPLICATION_REQUEST });
    const config = { withCredentials: true };
    // console.log("ok student")

    const data = await axios.get(`${host}/api/v1/asign/applications/${userId}?keyword=${searchWord}&page=${page}`, config);

    dispatch({ type: GET_ASIGN_APPLICATION_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: GET_ASIGN_APPLICATION_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

export const getAssignedApplicationsHistory = (userId, searchWord, page) => async (dispatch) => {
  try {
    dispatch({ type: GET_ASIGN_APPLICATION_HISTORY_REQUEST });
    const config = { withCredentials: true };
    // console.log("ok student")

    const data = await axios.get(`${host}/api/v1/assign/history?keyword=${searchWord}&page=${page}`, config);

    dispatch({ type: GET_ASIGN_APPLICATION_HISTORY_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: GET_ASIGN_APPLICATION_HISTORY_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

export const getAllStudentsLeads = (searchWord, userId, page) => async (dispatch) => {
  try {
    dispatch({ type: GET_LEADS_REQUEST });
    const config = { withCredentials: true };
    // console.log("ok student")

    const data = await axios.get(`${host}/api/v1/students-leads/${userId}?keyword=${searchWord}&page=${page}`, config);

    dispatch({ type: GET_LEADS_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: GET_LEADS_FAIL,
      payload: error?.response?.data.message,
    });
  }
};


export const getProcessedApplications = (userId, searchWord, page) => async (dispatch) => {
  try {
    dispatch({ type: GET_PROCESSED_APPLICATION_REQUEST });
    const config = { withCredentials: true };
    // console.log("ok student")

    const data = await axios.get(`${host}/api/v1/processed/leads/${userId}?keyword=${searchWord}&page=${page}`, config);

    dispatch({ type: GET_PROCESSED_APPLICATION_SUCCESS, payload: data.data });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: GET_PROCESSED_APPLICATION_FAIL,
      payload: error?.response?.data.message,
    });
  }
};
export const getApplications = (userId, page) => async (dispatch) => {
  console.log(userId, page)
  try {
    dispatch({ type: GET_APPLICATIONS_REQUEST });
    const config = { withCredentials: true };

    const data = await axios.get(`${host}/api/v1/user/applications/${userId}?page=${page}`, config);

    dispatch({ type: GET_APPLICATIONS_SUCCESS, payload: data.data });

  } catch (error) {
    dispatch({
      type: GET_APPLICATIONS_FAIL,
      payload: error?.response?.data.message,
    });
  }
};
export const clearError = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};

