

export const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST"
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS"
export const UPDATE_NOTIFICATION_FAIL = "UPDATE_NOTIFICATION_FAIL"
export const UPDATE_NOTIFICATION_RESET = "UPDATE_NOTIFICATION_RESET"

export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST"
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS"
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL"

export const GET_ALL_NOTIFICATIONS_REQUEST = "GET_ALL_NOTIFICATIONS_REQUEST"
export const GET_ALL_NOTIFICATIONS_SUCCESS = "GET_ALL_NOTIFICATIONS_SUCCESS"
export const GET_ALL_NOTIFICATIONS_FAIL = "GET_ALL_NOTIFICATIONS_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR"

