import * as XLSX from "xlsx";

const printUserExcel = (role, user, userData) => {
    // console.log("role, user, userData", role, user, userData)

    // Import the xlsx library
    // const { startDate, endDate } = dates;

    // function exportToExcel() {
    // Your data
    // const role = { startDate: "12-12-2024", endDate: "01-02-2024" };
    // const user = { name: "Ravi", email: "oravi@gmail.com" };
    // const userData = [
    //     {
    //         _id: { date: "2024-11-21" },
    //         details: [
    //             {
    //                 _id: "673ebaaeec2d7e3573ccd147",
    //                 userId: "671040a1b18bca0d9cec0782",
    //                 status: "in",
    //                 workingTime: null,
    //                 date: "2024-11-21T04:44:30.585Z",
    //                 createAt: "2024-11-21T04:44:30.132Z",
    //                 updateAt: "2024-11-21T04:44:30.132Z",
    //             },
    //         ],
    //     },
    //     // Other user data...
    // ];

    // Format data for Excel
    const data = [];
    data.push([" Start Date", role.startDate]);
    data.push([" End Date", role.endDate]);
    data.push([]);
    data.push(["Name", user.name]);
    data.push(["Email", user.email]);
    data.push([]);
    data.push(["Date",  "Status", , "Created At"]);

    userData.forEach((record) => {
        const date = record._id.date;
        record.details.forEach((detail) => {
            data.push([
                date,
                // detail.userId,
                detail.status,
                // detail.workingTime || "N/A",
                detail.createAt,
                // detail.updateAt,
            ]);
        });
    });

    // Create a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    // Create a workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "User Data");

    // Export to Excel
    XLSX.writeFile(workbook, "UserData.xlsx");
    // }

    // Call the function
    // exportToExcel();

}

export default printUserExcel