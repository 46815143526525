// 
exports.getCommentUser = (userId, users) => {
    const user = users?.find(item => item._id === userId);
    return user;
};

exports.getUser = (userId, users) => {
    const user = users?.find(item => item._id === userId);
    return user;
};

