import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateNotificationAction, clearError as nClearError, getNotificationsAction } from '../../actions/notificationAction';
import { getCommentText, getDateTimeNotification } from '../../utils/getDates';
import { getUser } from '../../utils/getUser';
import { FaUser } from 'react-icons/fa';
import { UPDATE_NOTIFICATION_RESET } from '../../constants/notification';
import { clearError, getMonitorUsersAction } from '../../actions/monitoringAction';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { TbMoodEmpty } from 'react-icons/tb';

const Notification = () => {
    const dispatch = useDispatch();

    // ----------------------------------------Notification-------------------------

    const [isNotification, setIsNotification] = useState(false)
    const { loading, notifications, resultPerPage, totalNotifications } = useSelector(state => state.getNotifications)
    const { isUpdated, error: nErr } = useSelector(state => state.updateNotification)
    const { user } = useSelector(state => state.users)


    useEffect(() => {
        if (nErr) {
            // toast.error(nErr)
            nClearError()
        }
        if (isUpdated) {
            // toast.success('Clean n')
            dispatch(getNotificationsAction(user?._id))

            dispatch({ type: UPDATE_NOTIFICATION_RESET })
        }
    }, [nErr, isUpdated])

    const updateNotificationHandle = (n_id) => {
        dispatch(updateNotificationAction(n_id))
    }


    // ----------------------------------------Notification end-------------------------

    // ==================================== get all users ====================================================

    const { error: getUserErr, users } = useSelector((state) => state.getMonitorUsers);

    useEffect(() => {
        dispatch(getMonitorUsersAction());
    }, []);

    useEffect(() => {
        if (getUserErr) {
            // console.log(error)
            toast.error(getUserErr)
            dispatch(clearError())
        }
    }, [getUserErr]);

    // ==================================== get all users end ====================================================

    return (
        <div>
            {
                // isNotification &&
                <div className="NotificationContainer">

                    <div className="notificationHeading">
                        <p>Notifications</p>

                        {
                            <Link to={'/notifications'} >View all</Link>
                        }

                    </div>

                    {
                        loading ? "Loading" : <>
                            <div>
                                {
                                    notifications?.length <= 0 ?
                                        <>
                                            <div className="emptyNotification">
                                                <TbMoodEmpty /> Empty
                                            </div>
                                        </>

                                        : <>
                                            {
                                                notifications?.map(item => {
                                                    const createdUser = getUser(item.createdUserId, users);
                                                    const date = getDateTimeNotification(item.createAt);
                                                    const comment = getCommentText(item.type);

                                                    return (
                                                        <Link to={item?.redirect_id === 'null' ? comment?.url : `${comment?.url}/${item.redirect_id}`} className="notificationItem" onClick={e => updateNotificationHandle(item?._id)}>
                                                            <p><FaUser />  {createdUser?.name} - {createdUser?.email}</p>
                                                            <h4>{comment?.text}</h4>
                                                            <p className="date">{date}</p>
                                                        </Link>
                                                    )
                                                }
                                                )
                                            }
                                        </>
                                }
                            </div>

                        </>
                    }


                </div>
            }
        </div>
    )
}

export default Notification