import { CLEAR_ERROR, GET_ALL_NOTIFICATIONS_FAIL, GET_ALL_NOTIFICATIONS_REQUEST, GET_ALL_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAIL, GET_NOTIFICATIONS_REQUEST, GET_NOTIFICATIONS_SUCCESS, UPDATE_NOTIFICATION_FAIL, UPDATE_NOTIFICATION_REQUEST, UPDATE_NOTIFICATION_RESET, UPDATE_NOTIFICATION_SUCCESS } from '../constants/notification';
  
  
  export const updateNotification = (state = { file: {} }, action) => {
    switch (action.type) {
        case UPDATE_NOTIFICATION_REQUEST:
        return {
          loading: true,
          isUpdated: false,
        };
  
      case UPDATE_NOTIFICATION_SUCCESS:
        return {
          ...state,
          loading: false,
          isUpdated: true,
          success: true,
          message: action.payload.message,
        };
  
  
      case UPDATE_NOTIFICATION_FAIL:
        return {
          ...state,
          loading: false,
          isUpdated: false,
          success: null,
          error: action.payload,
        };
      case UPDATE_NOTIFICATION_RESET:
        return {
          ...state,
          loading: false,
          isUpdated: false,
          error: null,
          success: null,
        };
  
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  export const getNotifications = (state = { comments: [] }, action) => {
    switch (action.type) {
      case GET_NOTIFICATIONS_REQUEST:
        return {
          loading: true,
        };
  
      case GET_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          notifications: action.payload.notifications,
          resultPerPage: action.payload.resultPerPage,
          totalNotifications: action.payload.totalNotifications
        };
  
      case GET_NOTIFICATIONS_FAIL:
        return {
          ...state,
          loading: false,
          notifications: null,
          error: action.payload,
        };
  
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  export const getAllNotifications = (state = { comments: [] }, action) => {
    switch (action.type) {
      case GET_ALL_NOTIFICATIONS_REQUEST:
        return {
          loading: true,
        };
  
      case GET_ALL_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          notifications: action.payload.notifications,
          resultPerPage: action.payload.resultPerPage,
          totalNotifications: action.payload.totalNotifications
        };
  
      case GET_ALL_NOTIFICATIONS_FAIL:
        return {
          ...state,
          loading: false,
          notifications: null,
          error: action.payload,
        };
  
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  
  