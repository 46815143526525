import axios from "axios";
import { CLEAR_ERROR, GET_ALL_NOTIFICATIONS_FAIL, GET_ALL_NOTIFICATIONS_REQUEST, GET_ALL_NOTIFICATIONS_SUCCESS, GET_NOTIFICATIONS_FAIL, GET_NOTIFICATIONS_REQUEST, GET_NOTIFICATIONS_SUCCESS, UPDATE_NOTIFICATION_FAIL, UPDATE_NOTIFICATION_REQUEST, UPDATE_NOTIFICATION_SUCCESS } from '../constants/notification.js'

const notificationHost = "https://notification.submitapp.in";
// const notificationHost = "http://localhost:5169";



export const updateNotificationAction = (_id,isSeen=false) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_NOTIFICATION_REQUEST });

        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.put(`${notificationHost}/api/v1/update-notification/${_id}`, {}, config);

        dispatch({ type: UPDATE_NOTIFICATION_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: UPDATE_NOTIFICATION_FAIL,
            payload: error?.response?.data.message,
        });
    }
};


export const getNotificationsAction = (userId, page) => async (dispatch) => {

    try {

        dispatch({ type: GET_NOTIFICATIONS_REQUEST });

        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.get(`${notificationHost}/api/v1/get-notification/${userId}?page=${page}`, config);

        dispatch({ type: GET_NOTIFICATIONS_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: GET_NOTIFICATIONS_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getAllNotificationsAction = (userId, page) => async (dispatch) => {

    try {

        dispatch({ type: GET_ALL_NOTIFICATIONS_REQUEST });

        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.get(`${notificationHost}/api/v1/get-all-notification/${userId}?page=${page}`, config);

        dispatch({ type: GET_ALL_NOTIFICATIONS_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: GET_ALL_NOTIFICATIONS_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};

