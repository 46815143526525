export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST"
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS"
export const UPLOAD_FILE_FAIL = "UPLOAD_FILE_FAIL"
export const UPLOAD_FILE_RESET = "UPLOAD_FILE_RESET"

export const DELETE_DOCUMENTS_REQUEST = "DELETE_DOCUMENTS_REQUEST"
export const DELETE_DOCUMENTS_SUCCESS = "DELETE_DOCUMENTS_SUCCESS"
export const DELETE_DOCUMENTS_FAIL = "DELETE_DOCUMENTS_FAIL"
export const DELETE_DOCUMENTS_RESET = "DELETE_DOCUMENTS_RESET"

export const GET_STUDENT_REQUEST = "GET_STUDENT_REQUEST"
export const GET_STUDENT_SUCCESS = "GET_STUDENT_SUCCESS"
export const GET_STUDENT_FAIL = "GET_STUDENT_FAIL"

export const GET_STUDENT_DETAIL_REQUEST = "GET_STUDENT_DETAIL_REQUEST"
export const GET_STUDENT_DETAIL_SUCCESS = "GET_STUDENT_DETAIL_SUCCESS"
export const GET_STUDENT_DETAIL_FAIL = "GET_STUDENT_DETAIL_FAIL"


export const GET_REPORT_REQUEST = "GET_REPORT_REQUEST"
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS"
export const GET_REPORT_FAIL = "GET_REPORT_FAIL"

export const GET_FILE_REQUEST = "GET_FILE_REQUEST"
export const GET_FILE_SUCCESS = "GET_FILE_SUCCESS"
export const GET_FILE_FAIL = "GET_FILE_FAIL"

export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST"
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS"
export const GET_DASHBOARD_FAIL = "GET_DASHBOARD_FAIL"

export const STUDENT_UPDATE_REQUEST = "STUDENT_UPDATE_REQUEST"
export const STUDENT_UPDATE_SUCCESS = "STUDENT_UPDATE_SUCCESS"
export const STUDENT_UPDATE_FAIL = "STUDENT_UPDATE_FAIL"
export const STUDENT_UPDATE_RESET = "STUDENT_UPDATE_RESET"

export const CREATE_STUDENT_REQUEST = "CREATE_STUDENT_REQUEST"
export const CREATE_STUDENT_SUCCESS = "CREATE_STUDENT_SUCCESS"
export const CREATE_STUDENT_FAIL = "CREATE_STUDENT_FAIL"
export const CREATE_STUDENT_RESET = "CREATE_STUDENT_RESET"

export const STUDENT_ASIGN_REQUEST = "STUDENT_ASIGN_REQUEST"
export const STUDENT_ASIGN_SUCCESS = "STUDENT_ASIGN_SUCCESS"
export const STUDENT_ASIGN_FAIL = "STUDENT_ASIGN_FAIL"
export const STUDENT_ASIGN_RESET = "STUDENT_ASIGN_RESET"

export const GET_ASIGN_APPLICATION_REQUEST = "GET_ASIGN_APPLICATION_REQUEST"
export const GET_ASIGN_APPLICATION_SUCCESS = "GET_ASIGN_APPLICATION_SUCCESS"
export const GET_ASIGN_APPLICATION_FAIL = "GET_ASIGN_APPLICATION_FAIL"

export const GET_LEADS_REQUEST = "GET_LEADS_REQUEST"
export const GET_LEADS_SUCCESS = "GET_LEADS_SUCCESS"
export const GET_LEADS_FAIL = "GET_LEADS_FAIL"

export const GET_ASIGN_APPLICATION_HISTORY_REQUEST = "GET_ASIGN_APPLICATION_HISTORY_REQUEST"
export const GET_ASIGN_APPLICATION_HISTORY_SUCCESS = "GET_ASIGN_APPLICATION_HISTORY_SUCCESS"
export const GET_ASIGN_APPLICATION_HISTORY_FAIL = "GET_ASIGN_APPLICATION_HISTORY_FAIL"


export const GET_PROCESSED_APPLICATION_REQUEST = "GET_PROCESSED_APPLICATION_REQUEST"
export const GET_PROCESSED_APPLICATION_SUCCESS = "GET_PROCESSED_APPLICATION_SUCCESS"
export const GET_PROCESSED_APPLICATION_FAIL = "GET_ASIGN_APPLICATION_FAIL"


export const GET_APPLICATIONS_REQUEST = "GET_APPLICATIONS_REQUEST"
export const GET_APPLICATIONS_SUCCESS = "GET_APPLICATIONS_SUCCESS"
export const GET_APPLICATIONS_FAIL = "GET_APPLICATIONS_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR"