import React, { useEffect } from 'react'
import { getUser } from '../../utils/getUser';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearError, getAttendanceReportsAction, getUserAttendanceAction } from '../../actions/monitoringAction';
import { useState } from 'react';
import printUserExcel from '../../utils/downloads';
import exportDataToExcel from '../../utils/downloadsReport';
// import  printUserExcel from '../../utils/downloads';
import './style.css'

const UserReportUi = ({ id, date1, date2 }) => {


    const dispatch = useDispatch();
    const { error, user: report_user, totalDates, userData, users: report_users, totalUsers, usersData, loading } = useSelector((state) => state.getReports);
    const { user } = useSelector((state) => state.users);

    const [userReport, setUserReport] = useState(false)
    const [usersReport, setUsersReport] = useState(false)
    const [isDownloadUser, setIsDownloadUser] = useState(false)
    const [isDownloadReports, setIsDownloadReports] = useState(false)

    useEffect(() => {
        if (error) {
            // console.log(error)
            toast.error(error)
            dispatch(clearError())
        }
    }, [error]);

    useEffect(() => {
        if (report_user) {
            setIsDownloadUser(true)
            setIsDownloadReports(false)

        }
        if (report_users) {
            setIsDownloadReports(true)
            setIsDownloadUser(false)
        }

    }, [report_user, report_users]);

    const getUserReportHandle = () => {
        console.log(id, date1, date2)
        if (!date1 || !date2) {
            toast.warn('Please select dates');
        } else if (!id) {
            toast.warn('Please select User');
        } else {
            setUserReport(true)
            setUsersReport(false)
            dispatch(getUserAttendanceAction(id, date1, date2))
        }
    }

    const getUsersReportHandle = () => {
        console.log(id, date1, date2)

        if (!date1 || !date2) {
            toast.warn('Please select dates');
        } else {
            setUserReport(false)
            setUsersReport(true)
            dispatch(getAttendanceReportsAction(date1, date2))
        }
    }



    const { error: getUserErr, users } = useSelector((state) => state.getMonitorUsers);

    console.log("all users ", users)

    const downloadUserReport = () => {
        console.log("report_user, totalDates, userData", report_user, totalDates, userData)

        const user = getUser(report_user.userId, users);

        printUserExcel({ startDate: date1, endDate: date2 }, user, userData)




    }

    const downloadAllReport = () => {
        // console.log("report_users, totalUsers, usersData", totalUsers, usersData)
        let usersDataMain = []

        for (let i = 0; i < usersData.length; i++) {
            const item = usersData[i];


            const user = getUser(item.userId, users);

            usersDataMain.push({
                email: user?.email,
                name: user?.name,
                role: user?.role,
                days: item.days,
                hours: item.hours
            })

        }


        console.log(usersDataMain)

        exportDataToExcel(date1, date2 ,  usersDataMain)



    }

    return (
        <div className='reportBox'>
            <button onClick={getUserReportHandle} className={isDownloadUser ? 'activeBorder userReportBtn' : 'userReportBtn'}> User Report</button>
            <button onClick={getUsersReportHandle} className={isDownloadReports ? 'activeBorder userBulkReportBtn' : 'userBulkReportBtn'}>All Users Bulk Report</button>

            <br />
            {
                (report_user || report_users) &&
                <>
                    {

                        (isDownloadUser && !isDownloadReports) ?
                            <button  onClick={downloadUserReport} className='userDownloadBtn'>Download User Report</button> :
                            <button onClick={downloadAllReport} className='userReportDownloadBtn'>Download All Report</button>
                    }
                </>
            }
        </div>
    )
}

export default UserReportUi