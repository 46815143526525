import React, { useEffect, useState } from "react";
import { FaDownload, FaEdit, FaGoogleDrive, FaLink } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/loading";
import MetaData from "../../components/MetaData";
import Sidebar from "../../components/Sidebar";
import { clearError } from "../../actions/universityAction";
import { getResourceAction } from "../../actions/resourceAction";
import {  getDateTime,getDate } from "../../utils/getDates";
import "./myResource.css";


const StudentDetails = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.users);
    
    const { id } = useParams();
    const { error, resource,resourceOwnerUser, loading } = useSelector((state) => state.getResource);
    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearError());
        }

    }, [error]);

    useEffect(() => {
        if (id) {
            dispatch(getResourceAction(id));
        }
    }, [id])
    


    const arrays = ["png", "jpg", "jpeg"]


    // ===================================================================


    const [isDownload, setIsDownload] = useState(false)
    const downloadFile = async (url, fileName) => {
        setIsDownload(true)
        try {
            const response = await fetch(url);
            const blob = await response.blob();

            setIsDownload(false)

            // Ensure the file type is supported (pdf, img, doc, docs)
            // const supportedTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

            // if (!supportedTypes.includes(blob.type)) {
            //   throw new Error('Unsupported file type.');
            // }

            // Create a URL for the blob and trigger download
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Release memory after download
            window.URL.revokeObjectURL(blobUrl);

        } catch (error) {
            console.error('Error downloading the file:', error);
            setIsDownload(false)

        }
    };

    console.log(resource)
    return (
        <div className="dashboardMain">
            <MetaData title={`Application - ${resource?.Name}`} />

            <div
                className={
                    window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"
                }
            >
                <Sidebar />
            </div>
            {loading ? (
                <Loading />
            ) : (
                <div className="dashboardContainer">
                    {/* <DashboardHeader heading={"Students Application"} /> */}

                    <div className="StudentDetailsBox">
                        <div className="studentUnderBox">
                            <div className="su-topHeader">
                                <h3>
                                    {"Resource"} <br />
                                    Last Update :{" "}
                                    {resource?.updatedAt === null ? "No Update" : getDateTime(resource?.updatedAt)}
                                </h3>

                                {/* <Link to={`/resource/update/${id}`} className="editBtn">Edit <FaEdit /> </Link> */}
                            </div>

                            <div className="update-by-container">
                                <h3>Created By</h3>
                                <p><b>Name : </b> {resourceOwnerUser?.name}</p>
                                <p><b> Email : </b> {resourceOwnerUser?.email}</p>
                                
                            </div>


                            <div className="st-d-item">
                                <div>
                                    <p>
                                        <b>Message :</b>
                                    </p>
                                    <p>{resource?.textMessage}</p>
                                </div>
                            </div>

                            <div className="st-d-item">
                                <div>
                                    <p>
                                        <b>Link</b>
                                    </p>
                                    <p>{resource?.link}</p>
                                </div>
                            </div>



                            <div className="st-d-item">
                                <div>
                                    <p>
                                        {" "}
                                        <b>Create At:</b>
                                    </p>
                                    <p>{resource?.createdAt && getDate(resource?.createdAt)}</p>

                                </div>
                            </div>




                            {
                                <>
                                    <div className="driveBox">
                                        {
                                            resource?.link &&
                                            <a href={resource?.link} target="_black" className="driveLinkBtn"> <FaLink />  Link</a>
                                        }
                                    </div>

                                </>
                            }

                            {/* images / documents  */}

                            <h3 className="doc_heading">{resource?.documentsFiles?.length !== 0 && "Documents"}</h3>
                            <div className="st-document-item">


                                {resource?.documentsFiles &&
                                    <>
                                        {
                                            resource?.documentsFiles?.map((item) => (
                                                <div className="doc-item">
                                                    <div>
                                                        <p>
                                                            <b></b> {item.doc_name}
                                                        </p>
                                                        {
                                                            isDownload ? '...' :
                                                                <button className="doc-download" onClick={e => downloadFile((item.url).split(':')[0] === 'https' ? item.url : 'https://gleam-portal-storage.blr1.cdn.digitaloceanspaces.com/' + item?.key, item.doc_name)}>
                                                                    <FaDownload />
                                                                </button>
                                                        }
                                                        {/* <a className="doc-download" target="_black" href={'https://gleam-portal-storage.blr1.cdn.digitaloceanspaces.com/'+item?.key}>
                              <FaEye />
                            </a> */}
                                                    </div>

                                                    <div>
                                                        {/* {
                              arrays.includes(item.url.split(".")[2])
                            } */}
                                                        {
                                                            // arrays.includes(item.url.split(".")[2]) ? (<img src={item.url} alt="file" />) : (<img src={'https://cdn-icons-png.flaticon.com/512/2246/2246713.png'} alt="file" />)
                                                            item.doc_type.split("/")[0] === 'image' ? (<img src={item?.url} alt="file" />) : (<img src={require('../../images/file.png')} alt="file" />)
                                                        }

                                                    </div>
                                                </div>
                                            )
                                            )
                                        }
                                    </>
                                }

                            </div>

                        </div>


                    </div>


                </div>
            )}
        </div>
    );
};

export default StudentDetails;
