import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './App.css';
// import os from 'os'

import ChangePassword from '../src/Pages/ChangePassword/ChangePassword.js';
import CreateUser from '../src/Pages/CreateUser/CreateUser.js';
import Dashboard from '../src/Pages/Dashboard/Dashboard';
import Profile from '../src/Pages/Profile/Profile.js';
import UpdateProfile from '../src/Pages/UpdateProfile/UpdateProfile.js';
import UpdateUser from '../src/Pages/UpdateUser/UpdateUser.js';
import UserManage from '../src/Pages/UserManage/UserManage.js';
import CreateApplication from './Pages/FileMangement/CreateApplication.js';

import ApplicationsRole from './Pages/FileMangement/ApplicationsRole.js';
import AsignedApplications from './Pages/FileMangement/AsignedApplications.js';
import FileManegment from './Pages/FileMangement/FileManagement.js';
import FileUpload from './Pages/FileMangement/FileUpload.js';
import MyAppHistory from './Pages/FileMangement/MyAppHistory.js';
import ProcessedApplications from './Pages/FileMangement/ProcessedApplications.js';
import StudentAsign from './Pages/FileMangement/StudentAsign.js';
import StudentDetails from './Pages/FileMangement/StudentDetails.js';
import StudentsApplication from './Pages/FileMangement/StudentsApplication.js';
import StudentsLeads from './Pages/FileMangement/StudentsLeads.js';
import StudentUpdate from './Pages/FileMangement/StudentUpdate.js';

import AllUniversities from './Pages/UniversityManagement/AllUniversities.js';
import MyUniversity from './Pages/UniversityManagement/MyUniversity.js';
import NewUniversity from './Pages/UniversityManagement/NewUniversity.js';
import UniversityManagement from './Pages/UniversityManagement/UniversityManagement.js';
import UpdateUniversity from './Pages/UniversityManagement/UpdateUniversity.js';

import AllResources from './Pages/resources/AllResources.js';
import MyResource from './Pages/resources/MyResource.js';
import MyResources from './Pages/resources/MyResources.js';
import NewResource from './Pages/resources/NewResource.js';
import UpdateResource from './Pages/resources/UpdateResource.js';


import Login from '../src/Pages/login/Login';

import InvitedUser from '../src/Pages/InvitedUser/InvitedUser.js';
import InviteForgetPassword from '../src/Pages/InviteForgetPassword/InviteForgetPassword.js';
// import { Link, useNavigate } from "react-router-dom";

import { useEffect, useState } from 'react';
// import debounce from 'lodash.debounce';
// import throttle from 'lodash.throttle';

import { useDispatch, useSelector } from 'react-redux';
import ForgetPassword from '../src/Pages/ForgetPassword/ForgetPassword.js';
import NoPage from '../src/Pages/NoPage/NoPage';
import { loadUser } from './actions/userAction';


import MonitorActiveApps from './Pages/monitor/MonitorActiveApps.js';
import MonitorPage from './Pages/monitor/MonitorPage.js';
import MonitorUserPage from './Pages/monitor/MonitorUserPage.js';

import AcceptTermsUi from './components/AcceptTermsUi.js';
import MonitorClockInOut from './Pages/monitor/MonitorClockInOut.js';
import MonitorRecordings from './Pages/monitor/MonitorRecordings.js';
import MonitorScreenshots from './Pages/monitor/MonitorScreenshots.js';
import TrackScreenshot from './Pages/monitor/TrackScreenshot.js';
import UserReport from './Pages/monitor/UserReport.js';
import NotificationPage from './Pages/NotificationPage.js';

import { clearError, getNotificationsAction } from './actions/notificationAction.js';




function App() {
  const [underLine, setUnderLine] = useState("");

  const { user } = useSelector(state => state.users)
  const dispatch = useDispatch();
  const [userId, setUserId] = useState('')



  useEffect(() => {
    dispatch(loadUser())
  }, [dispatch]);

  useEffect(() => {
    setUserId(user?._id)
    // console.log(user?._id)
  }, [user])


  // ==========================================================================================================
  useEffect(() => {
    const handleRightClick = (e) => {
      e.preventDefault();
    };

    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // -----------------------

  const [blackScreen, setBlackScreen] = useState(false);

  // ----------------------------------------Notification-------------------------
  const { loading, notifications, resultPerPage, totalNotifications, error } = useSelector(state => state.getNotifications)


  useEffect(() => {
    if (user?._id)
      dispatch(getNotificationsAction(user?._id))
  }, [dispatch, user]);

  useEffect(() => {
    if (error) {
      toast.error(error)
      clearError()
    }
  }, [error])

  // console.log(notifications)

  // ----------------------------------------Notification end-------------------------



  useEffect(() => {
    const port = window.location.hostname

    if (port !== "www.submitapp.in") {
      // window.location.href = "https://www.submitapp.in";
    }
  }, [])


  return ( 
    <BrowserRouter>
      {/* <Header underLine={underLine}/> */}
      {blackScreen && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          zIndex: 9999,
        }} >d</div>
      )}
      {
        user &&
        <AcceptTermsUi />
      }


      <Routes>


        <Route path='/password/reset/:token' element={<InviteForgetPassword setUnderLine={setUnderLine} />} />
        <Route path='/' element={<Login setUnderLine={setUnderLine} />} />
        <Route path='/email/invited-user/:token' element={<InvitedUser setUnderLine={setUnderLine} />} />
        {/* <Route path='/register' element={<Register setUnderLine={setUnderLine} />} /> */}
        <Route path='/forget-password' element={<ForgetPassword setUnderLine={setUnderLine} />} />

        <Route path='/dashboard' element={<Dashboard setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/user' element={<UserManage setUnderLine={setUnderLine} underLine={underLine} />} />

        <Route path='/create-user' element={<CreateUser setUnderLine={setUnderLine} />} />
        <Route path='/update/profile' element={<UpdateProfile setUnderLine={setUnderLine} />} />
        <Route path='/user/update/:id' element={<UpdateUser setUnderLine={setUnderLine} />} />
        <Route path='/change/password' element={<ChangePassword setUnderLine={setUnderLine} />} />
        <Route path='/profile' element={<Profile setUnderLine={setUnderLine} />} />

        <Route path='/file/management' element={<FileManegment setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/student/application' element={<StudentsApplication setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/asigned/applications' element={<AsignedApplications setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/processed/leads' element={<ProcessedApplications setUnderLine={setUnderLine} underLine={underLine} />} />

        <Route path='/assigned/applications/history' element={<MyAppHistory setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/student/leads' element={<StudentsLeads setUnderLine={setUnderLine} underLine={underLine} />} />

        <Route path='/file/upload' element={<FileUpload setUnderLine={setUnderLine} />} />
        <Route path='/create/application' element={<CreateApplication setUnderLine={setUnderLine} />} />
        <Route path='/student/details/update/:id' element={<StudentUpdate setUnderLine={setUnderLine} />} />
        <Route path='/student/details/:id' element={<StudentDetails setUnderLine={setUnderLine} />} />
        <Route path='/student/asign/:id' element={<StudentAsign setUnderLine={setUnderLine} />} />

        <Route path='/applications/:id' element={<ApplicationsRole setUnderLine={setUnderLine} />} />


        {/* ------------------ university Route6 ------------- */}
        <Route path='/university-management' element={<UniversityManagement setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/universities' element={<AllUniversities setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/university/update/:id' element={<UpdateUniversity setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/new-university' element={<NewUniversity setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/university/:id' element={<MyUniversity setUnderLine={setUnderLine} underLine={underLine} />} />

        {/* ------------------ Resources Route6 ------------- */}
        <Route path='/my-resources' element={<MyResources setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/resources' element={<AllResources setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/new-resource' element={<NewResource setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/resource/update/:id' element={<UpdateResource setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/resource/:id' element={<MyResource setUnderLine={setUnderLine} underLine={underLine} />} />


        {/* monitor */}
        <Route path='/monitoring' element={<MonitorPage setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/monitoring/:id' element={<MonitorUserPage setUnderLine={setUnderLine} underLine={underLine} />} />

        <Route path='/active-app' element={<MonitorActiveApps setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/clock-in-out' element={<MonitorClockInOut setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/screenshots' element={<MonitorScreenshots setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/recordings' element={<MonitorRecordings setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/report' element={<UserReport setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/track-screenshots' element={<TrackScreenshot setUnderLine={setUnderLine} underLine={underLine} />} />
        <Route path='/notifications' element={<NotificationPage setUnderLine={setUnderLine} underLine={underLine} />} />

        <Route path='*' element={<NoPage setUnderLine={setUnderLine} />} />
      </Routes>
      <ToastContainer />
      {/* <Footer /> */}

    </BrowserRouter>
  );
}

export default App;
