import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Loading from "../../components/Loading/loading";
import { useDispatch, useSelector } from "react-redux";
import "./StudentAsign.css";
import { clearError, getStudent, studentdAsign, studentdUpdate } from "../../actions/fileAction";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { STUDENT_ASIGN_RESET, STUDENT_UPDATE_RESET } from "../../constants/fileConstant";
import { getAllUsers, getAllUsersForAssign } from "../../actions/userAction";
import { MdOutlineDoNotDisturb } from "react-icons/md";
import { getAssignAt, getDateTime, getDateTimeIST, getDateTimeNotification, getUserForAssign } from "../../utils/getDates";

const StudentAsign = () => {
  const [userId, setUserId] = useState();

  const dispatch = useDispatch();
  const { error, isAsigned, message, loading } = useSelector(
    (state) => state.asign
  );
  const { error: studentEroor, student, assUsers, loading: studentLoading } = useSelector(
    (state) => state.oneStudent
  );
  const { error: allUserError, users, loading: allUserLoading } = useSelector(
    (state) => state.allUsers
  );
  const { user, isAuthenticated } = useSelector(
    (state) => state.users
  );

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
      // setSelectedMultiFile([])
    }
    if (studentEroor) {
      toast.error(studentEroor);
      dispatch(clearError());
      // setSelectedMultiFile([])
    }
    if (allUserError) {
      toast.error(allUserError);
      dispatch(clearError());
      // setSelectedMultiFile([])
    }
    if (isAsigned) {
      toast.success(message);
      navigate(-1);
      dispatch({ type: STUDENT_ASIGN_RESET });
      // setSelectedMultiFile([])
    }
    dispatch(getStudent(id))
    dispatch(getAllUsersForAssign())

  }, [error, isAsigned, dispatch]);

  const studentAsignHandler = (e) => {
    e.preventDefault()
    console.log(id, userId)
    dispatch(studentdAsign(userId, id))
  };

  // console.log(student)
  let usersAs = users;

  // users && users.forEach(item => {

  //   if (user.role !== 'agent') {
  //     // if (item.role !== 'supervisor') {
  //       usersAs.push(item)
  //     // }
  //   } 
  // else if (user.role === 'lead-counsellor') {

  //   if (item.role !== 'supervisor' && item.role !== 'admin') {
  //     if (item.region === user.region) {
  //       usersAs.push(item)
  //     }
  //   }
  // }

  // });



  // -------------------------------------------------------------------------------------------------------
  const Navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated)
      if (user?.role === 'agent') {
        Navigate('/dashboard')
      }
  }, [user])

  return (
    <div className="dashboardMain">
      <div
        className={
          window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"
        }
      >
        <Sidebar />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="dashboardContainer">
          {/* <DashboardHeader heading={"Students Application"} /> */}

          <div className="StudentAsignBox">
            <form action="" onSubmit={studentAsignHandler}>
              <h3>Assignment Application</h3>

              <div className="inputItem">
                <p>Assignment Application </p>
                <select required value={userId} onChange={e => setUserId(e.target.value)} className="inpBtn">
                  <option value="">Select User</option>
                  {
                    usersAs && usersAs?.map((item) => (
                      <>
                        {
                          item.role !== "agent" &&
                          <option value={item._id}>{item.name} , {item.role} </option>
                        }
                      </>

                    ))
                  }
                </select>
              </div>

              <div className="submitItem">
                <input type="submit" value={"Assign"} />
              </div>

              <p className="history">Assign History</p>

              {
                student && student.assignUser?.length !== 0 ? (
                  <div className="asignHisContainer">
                    <div className="heading">
                      <h3 className="btc1">User</h3>
                      <h3 className="btc2">Date</h3>
                    </div>
                    {/* <div className="asignItem">
                    <h3>Ravi Gupta</h3>
                    <h3>5/01/24</h3>
                  </div> */}

                    {/* {
                      assUsers && assUsers?.map((item) => {
                        const date = getAssignAt(item, student?.assignUser)
                        // console.log("Date" , date)
                        return(
                          <div className="asignItem">
                            <h3>{item?.name}</h3>
                            <h3>{date }</h3>
                          </div>
                        )
                      }
                      )
                    } */}

                    {
                      student && student?.assignUser?.map((item) => {

                        const user = getUserForAssign(item, assUsers)
                        // console.log("Date" , date)
                        return (
                          <div className="asignItem">
                            <h3>{user?.name} : {user?.email}</h3>
                            {/* <h3>{getDateTimeIST(item?.assigndAt)}</h3> */}
                            <h3>{getDateTimeNotification(item?.assigndAt)}</h3>
                          </div>
                        )
                      }
                      )
                    }

                  </div>
                ) : <div className="noUserAsignBox">
                  <MdOutlineDoNotDisturb />
                  <h2>Not Asign</h2>
                </div>
              }

            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentAsign;
