exports.getDate = (date) => {
    let dates = new Date(date)

    const d = dates.toString().split('G')[0]
    return `${d.split(' ')[0]} ${d.split(' ')[1]}  ${d.split(' ')[2]}  ${d.split(' ')[3]} `
}

exports.getDateTime = (date) => {
    // let dates = new Date(date)
    // console.log(date)
    // const d = dates.toString().split('G')[0]

    // return  `${d.split(' ')[0]} ${d.split(' ')[1]}  ${d.split(' ')[2]}  ${d.split(' ')[3]} - ${d.split(' ')[4]}  `

    const d = date?.toString().split('.')[0]

    const d2 = d?.toString().split('T')

    return d2 ? `${d2[0]} ${d2[1]} ` : "--"

}

exports.getDateTimeIST = (date) => {
    // code 1
    // let dates = new Date(date)
    // console.log(date)
    // const d = dates.toString().split('G')[0]

    // return  `${d.split(' ')[0]} ${d.split(' ')[1]}  ${d.split(' ')[2]}  ${d.split(' ')[3]} - ${d.split(' ')[4]}  `

    // code 2
    // let dateX = new Date(date)
    // const d = dateX?.toString().split('.')[0]

    // const d2 = d?.toString().split('T')

    // return d2 ? `${d2[0]} ` : "--"

    // code 3
    let dateX = new Date(date)

    dateX = dateX.toString()
    return dateX.split('G')[0]


}

exports.getDateTimeNotification = (date) => {
    let dateX = new Date(date)

    dateX = dateX.toString()
    return dateX.split('G')[0]

}

exports.getTime = (time) => {

    const t = time.split('-')

    return `${t[0]}:${t[1]}:${t[2]} `
}


exports.getAssignAt = (user, users) => {


    const assUser = users.find(item => item.userId === user._id);

    // console.log(assUser, assUser?.assigndAt)


    let dates = new Date(assUser?.assigndAt)

    const d = dates.toString().split('G')[0]


    return `${d.split(' ')[0]} ${d.split(' ')[1]}  ${d.split(' ')[2]}  ${d.split(' ')[3]}  ${d.split(' ')[4]}`


}

exports.getUserForAssign = (user, users) => {


    const assUser = users.find(item => user.userId === item._id);

    // console.log(assUser, assUser?.assigndAt)


    return assUser;


}


exports.isEditComment = (createAt) => {

    const now = new Date();
    const createdAtDate = new Date(createAt);

    // Calculate the difference in milliseconds
    const diffInMilliseconds = now - createdAtDate;

    // Convert the difference to minutes
    const diffInMinutes = diffInMilliseconds / (1000 * 60);

    // Check if the difference is within 30 minutes
    return diffInMinutes <= 30;

}

exports.isDeleteComment = (createAt) => {


    const now = new Date();
    const createdAtDate = new Date(createAt);

    // Calculate the difference in milliseconds
    const diffInMilliseconds = now - createdAtDate;

    // Convert the difference to hours
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

    // Check if the difference is within 24 hours
    return diffInHours <= 24;


}

const comments = [
    {
        type: 'comment',
        text: 'New Comment',
        url: '/student/details'
    },
    {
        type: 'new_app',
        text: 'New Application',
        url: '/student/details'
    },
    {
        type: 'update_lead',
        text: 'Update Lead',
        url: '/student/details'
    },
    {
        type: 'update_app',
        text: 'Update Application',
        url: '/student/details'
    },
    {
        type: 'assigned',
        text: 'Assigned',
        url: '/student/details'
    },

    {
        type: 'bulk_assigned',
        text: 'Bulk Assigned ',
        url: '/asigned/applications'
    },

    {
        type: 'bulk_app',
        text: 'Bulk Applications uploaded ',
        url: '/student/application'
    },

    {
        type: 'bulk_lead',
        text: 'Bulk Leads uploaded ',
        url: '/student/leads'
    },
    {
        type: 'update_resource',
        text: 'Update Resource',
        url: '/student/details'

    },
    {
        type: 'new_resource',
        text: 'New Resource',
        url: '/resource'

    }
]

exports.getCommentText = (type) => {

    const comment = comments.find(item => item.type === type)

    return comment
}