// Import xlsx library
import * as XLSX from "xlsx";

function exportDataToExcel(startDate, endDate, data) {
    // Dates information
    //   const startDate = "12-12-2024";
    //   const endDate = "01-21-2024";

    //   // Data array
    //   const data = [
    //     {
    //       days: 1,
    //       email: "agent@gmail.com",
    //       hours: 0.3799999999999999,
    //       name: "Ravi Agent",
    //       role: "agent",
    //     },
    //     {
    //       days: 0,
    //       email: "sangeeta@gleamrecruits.com",
    //       hours: 0,
    //       name: "Sangeetha",
    //       role: "processor",
    //     },
    //     {
    //       days: 0,
    //       email: undefined,
    //       hours: 0,
    //       name: undefined,
    //       role: undefined,
    //     },
    //   ];

    // Prepare data for Excel
    const excelData = [];
    excelData.push(["Start Date", startDate]);
    excelData.push(["End Date", endDate]);
    excelData.push([]);
    excelData.push(["Name", "Email", "Role", "Days", "Hours", ]);

    data.forEach((item) => {
        excelData.push([
            item.name || "N/A",
            item.email || "N/A", // Handle undefined
            item.role || "N/A",
            item.days,
            item.hours,
        ]);
    });

    // Create a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);

    // Create a workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    // Write to Excel and trigger download
    XLSX.writeFile(workbook, "ExportedData.xlsx");
}

// Call the function to export
export default exportDataToExcel;
